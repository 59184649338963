




























































































import { defineComponent, ref, Ref, computed, ComputedRef, watch } from '@vue/composition-api';
import { getProducts } from '@/admin/product';
import { DataTableHeader } from 'vuetify';
import { History, useHistory } from '@/admin/history';
import { useUser } from '@/admin/user';
import { downloadCsv } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import FcTableHeaderFilter from '@/components/FcTableHeaderFilter.vue';
import core from '@/admin/core';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import FcDateFilter from '@/components/FcDateFilter.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'ProductsHistories',
  components: {
    FcTableHeaderFilter,
    FcCheckFilter,
    FcDateFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    productId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup(props) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('products'));

    const { getHistories } = useHistory();
    const { userProperties } = useUser();
    const notification = useNotification();

    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      const properties = [
        { text: 'ステータス', value: 'productStatus' },
        { text: '決済日', value: 'createDate.dateTimeSeconds' },
        { text: '有効期限', value: 'expirationDate.dateTimeSeconds' },
        { text: '商品名', value: 'productName' },
        { text: '購入数', value: 'quantity' },
        { text: '合計金額', value: 'totalPrice' },
        { text: 'ユーザーID', value: 'username' },
        { text: 'メールアドレス', value: 'user.email' },
        ...userProperties.value.map(({ key, title }) => ({ text: title, value: `user.properties.${key}` })),
        { text: '会員番号', value: 'user.userNumber' },
        { text: '登録日', value: 'user.createDate' },
      ];
      if (process.env.VUE_APP_GROUP_TYPE === 'plan')
        properties.push({ text: '加入中のプラン', value: 'user.subscriptionNames' });
      if (process.env.VUE_APP_GROUP_TYPE === 'season')
        properties.push({ text: '加入中のシーズン', value: 'user.seasonNames' });
      properties.push({ text: '備考', value: 'note' });
      return properties;
    });
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);

    const items: Ref<History[]> = ref([]);
    const isLoading = ref(true);
    const nextToken = ref('');
    const nextLoading = ref(false);
    const isDisplayItem = ref(false);
    const filterDates: Ref<(number | null)[]> = ref([null, null]);

    // フリーワードで絞り込み
    const searchWord = ref(props.productId);

    // 商品で絞り込み
    const selectedProductValues: Ref<string[]> = ref([]);
    const products: Ref<Option[]> = ref([]);
    getProducts().then((result) => {
      products.value = result.map((product) => ({
        text: product.productName,
        value: product.productId,
        isActive: product.isArchive,
      }));
    });

    // 表示するアイテム一覧
    const histories = computed(() => {
      return (
        items.value
          .map((item) => {
            const targetProduct = products.value.find((product) => product.value === item.productId);
            const isArchive = !!targetProduct?.isActive;
            return {
              ...item,
              productName: targetProduct?.text || item.productId || '',
              isArchive: isArchive,
              productStatus: isArchive ? '削除済' : '販売中',
            };
          })
          // 削除スイッチによる絞り込み
          .filter((item) => (!isDisplayItem.value ? !item.isArchive : item))
          // フリーワードによる絞り込み
          .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value))
          // 商品名による絞り込み
          .filter(
            (item) =>
              !selectedProductValues.value.length ||
              !!selectedProductValues.value.find((productId) => item.productId === productId)
          )
      );
    });
    watch(
      () => filterDates.value,
      () => {
        isLoading.value = true;
        items.value = [];
        getHistories('product', nextToken.value, undefined, filterDates.value[0], filterDates.value[1])
          .then((result) => {
            items.value = result.histories;
            nextLoading.value = false;
            isLoading.value = false;
            nextToken.value = result.nextToken || '';
          })
          .catch((error) => {
            notification.error(error);
            isLoading.value = false;
          });
      }
    );
    // もっと読み込む
    const loadNext = async () => {
      nextLoading.value = true;
      getHistories('product', nextToken.value).then((result) => {
        items.value = [...items.value, ...result.histories];
        nextLoading.value = false;
        nextToken.value = result.nextToken || '';
      });
    };

    // ダウンロードボタン
    const download = () => {
      // actionsの列は削除
      const csvHeaders = displayHeaders.value.slice(0, -1);
      const csvItems = histories.value;
      downloadCsv(csvHeaders, csvItems, 'product-histories.csv');
    };

    // テーブル高さ
    const tableHeight = window.innerHeight - 64 - 40;

    return {
      pageTitle: '購入履歴',
      myRoleSettings,
      items,
      headers,
      displayHeaders,
      isLoading,
      loadNext,
      nextToken,
      nextLoading,
      searchWord,
      histories,
      tableHeight,
      selectedProductValues,
      products,
      isDisplayItem,
      download,
      isPermitted: core.isPermitted,
      filterDates,
    };
  },
});
